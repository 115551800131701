<template>
	<div class="mm-top-navbar">
		<div class="mm-navbar-custom">
			<nav class="navbar navbar-expand-lg navbar-light p-0">
				<div class="mm-navbar-logo d-flex align-items-center justify-content-between" @click="miniSidebar">
					<i class="ri-menu-line wrapper-menu"></i>
					<router-link :to="{ name: 'dashboard' }" class="header-logo">
						<img :src="sidelogo" class="img-fluid rounded-normal" alt="logo" />
					</router-link>
				</div>
				<div class="mm-search-bar device-search m-auto"></div>
				<div class="d-flex align-items-center">
					<!-- <ModeSwitch /> -->
					<b-navbar-toggle target="nav-collapse" class="mr-2 text-primary">
						<i class="ri-menu-3-line"></i>
					</b-navbar-toggle>
					<b-collapse id="nav-collapse" is-nav>
						<ul class="navbar-nav ml-auto navbar-list align-items-center">
							<li class="nav-item nav-icon search-content" v-nav-toggle>
								<a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<svg class="svg-icon text-primary" id="h-suns" height="20" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
									</svg>
								</a>
								<div class="mm-search-bar mm-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
									<form action="#" class="searchbox p-2">
										<div class="form-group mb-0 position-relative">
											<input type="text" class="text search-input font-size-12" placeholder="type here to search..." />
											<a href="#" class="search-link"><i class="las la-search"></i></a>
										</div>
									</form>
								</div>
							</li>
							<Fullscreen class="nav-item nav-icon dropdown full-screen" extraclass="nav-item nav-icon dropdown" />
							<li class="nav-item nav-icon dropdown" v-nav-toggle>
								<a class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<img src="@/assets/images/user/user.png" class="img-fluid avatar-rounded" alt="user" />
								</a>
								<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
									<!-- <li class="dropdown-item d-flex svg-icon">
										<svg class="svg-icon mr-0 text-primary" id="h-03-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
											/>
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
										</svg>
										<router-link :to="{ name: 'profile' }">Profile</router-link>
									</li> -->
									<li class="dropdown-item  d-flex svg-icon border-top">
										<svg class="svg-icon mr-0 text-primary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
										</svg>
										<a @click="logout()">Logout</a>
									</li>
								</ul>
							</li>
						</ul>
					</b-collapse>
				</div>
			</nav>
		</div>
	</div>
</template>
<script>
	import { core } from "@/config/pluginInit";
	import Fullscreen from "../fullscreen/Fullscreen";
	import { mapGetters } from "vuex";

	export default {
		name: "HeaderStyle1",
		components: { Fullscreen },
		computed: {
			...mapGetters({
				logo: "darklogo",
				sidelogo: "logo",
			}),
		},
		methods: {
			miniSidebar() {
				core.triggerSet();
			},
			logout() {
				localStorage.removeItem("token");
				this.$router.push({ name: "auth.login" });
				this.$vToastify.success("You have been logged out");
			},
		},
	};
</script>
