<template>
	<footer class="mm-footer">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-6">
					<ul class="list-inline mb-0"></ul>
				</div>
				<!--<div class="col-lg-6 text-right">
					Copyright 2021© <a href="#">{{ appName }}</a> All Rights Reserved.
				</div>-->
				<div class="col-lg-6 text-right">
					Copyright 2022© <a href="#">STUDENT APP</a> All Rights Reserved.
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
	import { mapGetters } from "vuex";
	export default {
		name: "Footer",
		prop: {},
		computed: {
			...mapGetters({
				appName: "appName",
			}),
		},
	};
</script>
