<template>
	<div class="mm-sidebar  sidebar-default hide-mobile-menu">
		<div class="mm-sidebar-logo d-flex align-items-center justify-content-between">
			<router-link :to="{ name: 'dashboard' }" class="header-logo">
				<img :src="sidelogo" class="img-fluid rounded-normal" alt="logo" />
				<!-- <h5 class="text-center text-primary"><strong>STUDENT APP</strong></h5> -->
			</router-link>
			<div class="side-menu-bt-sidebar" @click="miniSidebar">
				<i class="las la-bars wrapper-menu"></i>
			</div>

			<div class="close-bt-sidebar" @click="closeSidebar">
				<i class="fas fa-times"></i>
			</div>
		</div>
		<div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar">
			<nav class="mm-sidebar-menu">
				<ul id="mm-sidebar-toggle" class="side-menu">
					<li :class="checkActive('dashboard') ? 'active' : ''">						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('dashboard')">
							<i class="fas fa-tachometer-alt"></i>
							<span class="">Dashboard</span>
						</a>
					</li>

					<!-- Students sidebar -->
					<li :class="checkActive('students') ? 'active' : ''">						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('students')">
							<i class="fas fa-user-graduate"></i>
							<span class="">Students</span>
						</a>
					</li>
					<!-- Students sidebar ends -->	

					<!-- Applied Documents sidebar -->
					<li :class="checkActive('applied.documents') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('applied.documents')">
							<i class="fas fa-paste"></i>
							<span class="">Applied Documents</span>
						</a>
					</li>
					<!-- Applied Documents sidebar ends -->		

					<!-- Courses sidebar -->
					<li :class="checkActive('courses') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('courses')">
							<i class="fas fa-graduation-cap"></i>
							<span class="">Courses</span>
						</a>
					</li>
					<!-- Courses sidebar ends -->

					<!-- Departments sidebar -->
					<li :class="checkActive('departments') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('departments')">
							<i class="fas fa-shapes"></i>
							<span class="">Departments</span>
						</a>
					</li>
					<!-- Departments sidebar ends -->	

					<!-- Branches sidebar -->
					<li :class="checkActive('branches') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('branches')">
							<i class="fas fa-code-branch"></i>
							<span class="">Branches</span>
						</a>
					</li>
					<!-- Branches sidebar ends -->	

					<!-- Sub Branches sidebar -->
					<li :class="checkActive('subbranches') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('subbranches')">
							<i class="fas fa-code-branch"></i>
							<span class="">Sub Branches</span>
						</a>
					</li>
					<!-- Sub Branches sidebar ends -->	

					<!-- Centres sidebar -->
					<li :class="checkActive('centres') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('centres')">
							<i class="fas fa-landmark"></i>
							<span class="">Centres</span>
						</a>
					</li>
					<!-- Centres sidebar ends -->	

					<!-- Sessions sidebar -->
					<li :class="checkActive('sessions') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('sessions')">
							<i class="fas fa-calendar-alt"></i>
							<span class="">Sessions</span>
						</a>
					</li>
					<!-- Sessions sidebar ends -->					

					<!-- Reports sidebar -->
					<li :class="checkActive('reports.students') ? 'active' : ''">
						
						<a href="#" class="svg-icon" @click.prevent="hideMobileSidebar('reports.students')">
							<i class="fas fa-file-alt"></i>
							<span class="">Student Report</span>
						</a>
					</li>
					<!-- Reports sidebar ends -->							

					
					
				</ul>
			</nav>

			<div class="pt-5 pb-2"></div>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from "vuex";
	import { core } from "@/config/pluginInit";
	export default {
		name: "SidebarStyle",
		props: {
			items: Array,
		},
		data() {
			return {
				homeurl: "",
				reports: ["reports.test", "reports.service", "reports.visit", "reports.preventive", "reports.breakdown", "reports.stock"],
				auth: ["auth.login"],
				error: ["error.404", "error.500"],
				pages: [],
			};
		},
		mounted() {
			core.SmoothScrollbar();
		},
		destroyed() {
			core.SmoothScrollbar();
		},
		computed: {
			...mapGetters({
				logo: "darklogo",
				sidelogo: "logo",
			}),
		},
		methods: {
			checkActive(route) {
				
				if (this.$route.name == route) {
					return true;
				}
				// if (route.includes(this.$route.name)) {
				// 	return true;
				// }
			},
			miniSidebar() {
				core.triggerSet();

			},
			showCollapse(collapseId) {
				document
					.getElementById(collapseId)
					.closest("li")
					.classList.add("active");
			},
			hideCollapse() {
				const list = document.querySelector(".mm-sidebar-menu").querySelectorAll(".submenu");
				Array.from(list, (elem) => {
					if (elem.closest(".active") !== null && elem.closest(".active") !== undefined) {
						elem.closest(".active").classList.remove("active");
					}
				});
			},

			hideMobileSidebar(goto_path) {
				//custom code to fix mobile menu responsive
			    const elmExist = core.checkElement('class', 'mm-sidebar')
			    if (elmExist) {
			      	const sidebar = document.querySelector('.mm-sidebar')
			      	sidebar.classList.add('hide-mobile-menu')

			      	const body = document.querySelector('body')
      				body.classList.remove('sidebar-mobile')
      				body.classList.remove('sidebar-main')
			    }
			    this.$router.push({ name: goto_path }); 
			},

			closeSidebar() {
				//custom code to fix mobile menu responsive
			    const elmExist = core.checkElement('class', 'mm-sidebar')
			    if (elmExist) {
			      	const sidebar = document.querySelector('.mm-sidebar')
			      	sidebar.classList.add('hide-mobile-menu')

			      	const body = document.querySelector('body')
      				body.classList.remove('sidebar-mobile')
      				body.classList.remove('sidebar-main')
			    }
			}
		},
	};
</script>
